export default {
  title: "Pricing & Packaging",
  description: `Providing best <br />
                pricing for you`,
  monthly: "Monthly",
  yearly: "Yearly",
  purchase: "Purchase Now",
  groupCourses: {
    title: "Group",
    intensity: [
      {
        key: "light",
        title: "Light",
        description: "A beginner-friendly course with a lighter schedule.",
        features: [
          "Live online classes once a week",
          "Q&A sessions after each class",
          "Access to recorded sessions",
          "Career guidance every 3 months",
          "Certificate of completion",
          "Community group access",
          "Monthly progress reports",
        ],
      },
      {
        key: "semiIntensive",
        title: "Semi-Intensive",
        description:
          "More engagement with additional live classes and career-focused content.",
        features: [
          "Live online classes twice a week",
          "Q&A sessions after each class",
          "Access to recorded sessions",
          "Monthly career guidance sessions",
          "Certificate of completion",
          "Job interview preparation (bi-monthly)",
          "Group projects with feedback",
          "Study materials and resources",
          "Monthly progress reports",
        ],
      },
      {
        key: "intensive",
        title: "Intensive",
        description:
          "The most immersive group course and personalized mentorship.",
        features: [
          "Live online classes four times a week",
          "Q&A sessions after each class",
          "Access to recorded sessions",
          "Bi-weekly career guidance sessions",
          "Certificate of completion",
          "Job interview preparation (monthly)",
          "Group projects with mentorship feedback",
          "Exclusive webinars and workshops",
          "Personalized study materials",
          "Weekly progress reports",
        ],
      },
    ],
  },
  individualCourses: {
    title: "Individual",
    intensity: [
      {
        key: "light",
        title: "Light",
        description:
          "A flexible course with personalized plans and moderate interaction.",
        features: [
          "Personalized learning plan",
          "Live online classes once a week",
          "Q&A sessions after each class",
          "Access to recorded sessions",
          "Career guidance every 3 months",
          "Certificate of completion",
          "One-on-one project feedback",
          "Study materials and resources",
          "Monthly email support",
        ],
      },
      {
        key: "semiIntensive",
        title: "Semi-Intensive",
        description:
          "More frequent one-on-one interaction and expert mentorship.",
        features: [
          "Personalized learning plan",
          "Live online classes twice a week",
          "Q&A sessions after each class",
          "Access to recorded sessions",
          "Monthly career guidance sessions",
          "Certificate of completion",
          "Project mentorship with expert feedback",
          "Job interview preparation (monthly)",
          "Priority study materials and resources",
          "Bi-weekly progress reports",
          "Priority email and chat support",
        ],
      },
      {
        key: "intensive",
        title: "Intensive",
        description:
          "An all-in-one intensive program with expert mentorship and support.",
        features: [
          "Personalized learning plan",
          "Live online classes four times a week",
          "Q&A sessions after each class",
          "Access to recorded sessions",
          "Bi-weekly career guidance sessions",
          "Certificate of completion",
          "Project mentorship with in-depth expert feedback",
          "Job interview preparation (bi-weekly)",
          "Exclusive webinars and advanced resources",
          "Weekly progress reports",
          "Priority email and chat support",
        ],
      },
    ],
  },
};
