import ar from "./ar";
import de from "./de";
import en from "./en";
import fr from "./fr";

export default {
  en: en,
  fr: fr,
  de: de,
  ar: ar,
};
