import {
  ALGERIA,
  COUNTRIES_USING_EURO,
  CURRENT_OFFER,
} from "~/config/constants";

const PRICING_TABLE = {
  DZD: {
    groupCourses: {
      light: 5000,
      semiIntensive: 12000,
      intensive: 25000,
    },
    individualCourses: {
      light: 12000,
      semiIntensive: 20000,
      intensive: 30000,
    },
  },
  EURO: {
    groupCourses: {
      light: 129,
      semiIntensive: 199,
      intensive: 399,
    },
    individualCourses: {
      light: 249,
      semiIntensive: 399,
      intensive: 799,
    },
  },
  USD: {
    groupCourses: {
      light: 129,
      semiIntensive: 199,
      intensive: 399,
    },
    individualCourses: {
      light: 249,
      semiIntensive: 399,
      intensive: 799,
    },
  },
};

export type CourseType = "groupCourses" | "individualCourses";
export type CourseIntensity = "light" | "semiIntensive" | "intensive";

function getPricing(
  country: string,
  courseType: CourseType,
  courseIntensity: CourseIntensity,
): number {
  if (country === ALGERIA) {
    return PRICING_TABLE.DZD[courseType][courseIntensity];
  }

  if (COUNTRIES_USING_EURO.includes(country)) {
    return PRICING_TABLE.EURO[courseType][courseIntensity];
  }

  return PRICING_TABLE.USD[courseType][courseIntensity];
}

export function getPricingFor(
  country: string,
  courseType: CourseType,
  courseIntensity: CourseIntensity,
  locale: string,
  discounted = false,
): string {
  let pricing = getPricing(country, courseType, courseIntensity);
  if (discounted) {
    pricing = Math.round(pricing * (1 - Number(CURRENT_OFFER) / 100));
  }

  if (country === ALGERIA) {
    if (locale === "ar") {
      return `${pricing} دج`;
    }
    return `DZD ${pricing}`;
  }

  if (COUNTRIES_USING_EURO.includes(country)) {
    return `€${pricing}`;
  }

  return `$${pricing}`;
}

export function getDiscountedPrice(
  country: string,
  courseType: CourseType,
  courseIntensity: CourseIntensity,
  locale: string,
): string {
  return getPricingFor(country, courseType, courseIntensity, locale, true);
}
