/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";

interface PricingTabProps {
  label: string;
  isActive: boolean;
  onClick: React.MouseEventHandler;
}

const PricingTab: React.FC<PricingTabProps> = ({
  label,
  isActive,
  onClick,
}) => {
  return (
    <li className={isActive ? "active" : ""}>
      <a href="" onClick={onClick}>
        {label}
      </a>
    </li>
  );
};

PricingTab.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PricingTab;
