import { Link } from "@remix-run/react";
import PropTypes from "prop-types";

import { getLocalizedPagePath } from "~/helpers/navigationHelper";

import "./PricingTable.scss";

interface PricingTableProps {
  title: string;
  description: string;
  price: string;
  discountedPrice: string;
  period: string;
  features: string[];
  actionLabel: string;
}

const PricingTable: React.FC<PricingTableProps> = ({
  title,
  description,
  price,
  discountedPrice,
  period,
  features,
  actionLabel,
}) => {
  const hasDiscount = price !== discountedPrice;

  return (
    <div className="col-lg-4">
      <div className="cs-pricing_table cs-style1">
        <h2 className="cs-pricing_title">{title}</h2>
        <div className="cs-pricing_info">
          <div className="cs-price">
            <h3
              className={
                "cs-white_color " + hasDiscount ? "discounted-price" : ""
              }
            >
              {price}
            </h3>
            {hasDiscount ? (
              <h3 className="cs-white_color">{discountedPrice}/</h3>
            ) : null}
            <span className="cs-accent_color">{period}</span>
          </div>
          {/* <div className="cs-price">
            <h3 className="cs-white_color">{discountedPrice}/</h3>
            <span className="cs-accent_color">{period}</span>
          </div> */}
          <div className="cs-price_text">{description}</div>
        </div>
        <ul className="cs-pricing_feature cs-mp0">
          {features.map((feature: string) => {
            return (
              <li key={`price-table-item-${feature}`}>
                <span className="cs-feature_icon cs-accent_color">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span>{feature}</span>
              </li>
            );
          })}
        </ul>
        <div className="cs-pricing_btn_wrap">
          <Link to={getLocalizedPagePath("/contact")} className="cs-text_btn">
            <span>{actionLabel}</span>
            <svg
              width="26"
              height="12"
              viewBox="0 0 26 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                fill="currentColor"
              />
            </svg>
          </Link>
        </div>
      </div>
      <div className="cs-height_25 cs-height_lg_25"></div>
    </div>
  );
};

PricingTable.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  discountedPrice: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  actionLabel: PropTypes.string.isRequired,
};

export default PricingTable;
