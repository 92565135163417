export default {
  title: "Tarification & Forfaits",
  description: `Nous fournissons les meilleurs <br />
                tarifs pour vous`,
  monthly: "Mensuel",
  yearly: "Annuel",
  purchase: "Acheter maintenant",
  groupCourses: {
    title: "Groupe",
    intensity: [
      {
        key: "light",
        title: "Léger",
        description:
          "Un cours convivial pour les débutants avec un emploi du temps plus léger.",
        features: [
          "Cours en ligne en direct une fois par semaine",
          "Sessions de questions-réponses après chaque cours",
          "Accès aux sessions enregistrées",
          "Conseil de carrière tous les 3 mois",
          "Certificat de fin d'études",
          "Accès au groupe communautaire",
          "Rapports de progression mensuels",
        ],
      },
      {
        key: "semiIntensive",
        title: "Semi-Intensif",
        description:
          "Plus d'engagement avec des cours en direct supplémentaires.",
        features: [
          "Cours en ligne en direct deux fois par semaine",
          "Sessions de questions-réponses après chaque cours",
          "Accès aux sessions enregistrées",
          "Sessions de conseil de carrière mensuelles",
          "Certificat de fin d'études",
          "Préparation aux entretiens d'embauche (bimensuel)",
          "Projets de groupe avec retour d'information",
          "Matériel d'étude et ressources",
          "Rapports de progression mensuels",
        ],
      },
      {
        key: "intensive",
        title: "Intensif",
        description:
          "Le cours de groupe le plus immersif avec mentorat personnalisé.",
        features: [
          "Cours en ligne en direct quatre fois par semaine",
          "Sessions de questions-réponses après chaque cours",
          "Accès aux sessions enregistrées",
          "Sessions de conseil de carrière bimensuelles",
          "Certificat de fin d'études",
          "Préparation aux entretiens d'embauche (mensuelle)",
          "Projets de groupe avec retour d'information du mentor",
          "Webinaires et ateliers exclusifs",
          "Matériel d'étude personnalisé",
          "Rapports de progression hebdomadaires",
        ],
      },
    ],
  },
  individualCourses: {
    title: "Individuel",
    intensity: [
      {
        key: "light",
        title: "Léger",
        description:
          "Un cours flexible avec des plans personnalisés et une interaction modérée.",
        features: [
          "Plan d'apprentissage personnalisé",
          "Cours en ligne en direct une fois par semaine",
          "Sessions de questions-réponses après chaque cours",
          "Accès aux sessions enregistrées",
          "Conseil de carrière tous les 3 mois",
          "Certificat de fin d'études",
          "Retour d'information sur le projet en tête-à-tête",
          "Matériel d'étude et ressources",
          "Support par e-mail mensuel",
        ],
      },
      {
        key: "semiIntensive",
        title: "Semi-Intensif",
        description: "Interaction plus fréquente et mentorat d'expert.",
        features: [
          "Plan d'apprentissage personnalisé",
          "Cours en ligne en direct deux fois par semaine",
          "Sessions de questions-réponses après chaque cours",
          "Accès aux sessions enregistrées",
          "Sessions de conseil de carrière mensuelles",
          "Certificat de fin d'études",
          "Mentorat de projet avec retour d'expert",
          "Préparation aux entretiens d'embauche (mensuelle)",
          "Matériel et ressources d'étude prioritaires",
          "Rapports de progression bimensuels",
          "Support par e-mail et chat prioritaire",
        ],
      },
      {
        key: "intensive",
        title: "Intensif",
        description:
          "Un programme intensif tout-en-un avec mentorat et support d'expert.",
        features: [
          "Plan d'apprentissage personnalisé",
          "Cours en ligne en direct quatre fois par semaine",
          "Sessions de questions-réponses après chaque cours",
          "Accès aux sessions enregistrées",
          "Sessions de conseil de carrière bimensuelles",
          "Certificat de fin d'études",
          "Mentorat de projet avec retour d'expert approfondi",
          "Préparation aux entretiens d'embauche (bimensuelle)",
          "Webinaires exclusifs et ressources avancées",
          "Rapports de progression hebdomadaires",
          "Support par e-mail et chat prioritaire 24/7",
        ],
      },
    ],
  },
};
