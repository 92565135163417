export default {
  title: "Preisgestaltung & Pakete",
  description: `Die besten <br />
                Preise für Sie anbieten`,
  monthly: "Monatlich",
  yearly: "Jährlich",
  purchase: "Jetzt kaufen",
  groupCourses: {
    title: "Gruppe",
    intensity: [
      {
        key: "light",
        title: "Leicht",
        description:
          "Ein anfängerfreundlicher Kurs mit einem leichteren Zeitplan.",
        features: [
          "Live-Online-Kurse einmal pro Woche",
          "Q&A-Sitzungen nach jedem Kurs",
          "Zugang zu aufgezeichneten Sitzungen",
          "Karriereberatung alle 3 Monate",
          "Zertifikat über den Abschluss",
          "Zugang zur Gemeinschaftsgruppe",
          "Monatliche Fortschrittsberichte",
        ],
      },
      {
        key: "semiIntensive",
        title: "Semi-Intensiv",
        description:
          "Mehr Engagement mit zusätzlichen Live-Kursen und karriereorientiertem Inhalt.",
        features: [
          "Live-Online-Kurse zweimal pro Woche",
          "Q&A-Sitzungen nach jedem Kurs",
          "Zugang zu aufgezeichneten Sitzungen",
          "Monatliche Karriereberatungssitzungen",
          "Zertifikat über den Abschluss",
          "Vorbereitung auf Vorstellungsgespräche (alle zwei Monate)",
          "Gruppenprojekte mit Feedback",
          "Studienmaterialien und Ressourcen",
          "Monatliche Fortschrittsberichte",
        ],
      },
      {
        key: "intensive",
        title: "Intensiv",
        description: "Der immersivste Gruppenkurs und persönliches Mentoring.",
        features: [
          "Live-Online-Kurse viermal pro Woche",
          "Q&A-Sitzungen nach jedem Kurs",
          "Zugang zu aufgezeichneten Sitzungen",
          "Zweiwöchentliche Karriereberatungssitzungen",
          "Zertifikat über den Abschluss",
          "Vorbereitung auf Vorstellungsgespräche (monatlich)",
          "Gruppenprojekte mit Mentoren-Feedback",
          "Exklusive Webinare und Workshops",
          "Personalisierte Studienmaterialien",
          "Wöchentliche Fortschrittsberichte",
        ],
      },
    ],
  },
  individualCourses: {
    title: "Einzel",
    intensity: [
      {
        key: "light",
        title: "Leicht",
        description:
          "Ein flexibler Kurs mit personalisierten Plänen und moderater Interaktion.",
        features: [
          "Personalisierter Lernplan",
          "Live-Online-Kurse einmal pro Woche",
          "Q&A-Sitzungen nach jedem Kurs",
          "Zugang zu aufgezeichneten Sitzungen",
          "Karriereberatung alle 3 Monate",
          "Zertifikat über den Abschluss",
          "Einzelne Projektfeedback",
          "Studienmaterialien und Ressourcen",
          "Monatlicher E-Mail-Support",
        ],
      },
      {
        key: "semiIntensive",
        title: "Semi-Intensiv",
        description:
          "Häufigere individuelle Interaktion und Expertenmentoring.",
        features: [
          "Personalisierter Lernplan",
          "Live-Online-Kurse zweimal pro Woche",
          "Q&A-Sitzungen nach jedem Kurs",
          "Zugang zu aufgezeichneten Sitzungen",
          "Monatliche Karriereberatungssitzungen",
          "Zertifikat über den Abschluss",
          "Projektmentoring mit Expertenfeedback",
          "Vorbereitung auf Vorstellungsgespräche (monatlich)",
          "Priorisierte Studienmaterialien und Ressourcen",
          "Zweiwöchentliche Fortschrittsberichte",
          "Priorisierter E-Mail- und Chatsupport",
        ],
      },
      {
        key: "intensive",
        title: "Intensiv",
        description:
          "Ein umfassendes Intensivprogramm mit Expertenmentoring und Unterstützung.",
        features: [
          "Personalisierter Lernplan",
          "Live-Online-Kurse viermal pro Woche",
          "Q&A-Sitzungen nach jedem Kurs",
          "Zugang zu aufgezeichneten Sitzungen",
          "Zweiwöchentliche Karriereberatungssitzungen",
          "Zertifikat über den Abschluss",
          "Projektmentoring mit detailliertem Expertenfeedback",
          "Vorbereitung auf Vorstellungsgespräche (alle zwei Wochen)",
          "Exklusive Webinare und erweiterte Ressourcen",
          "Wöchentliche Fortschrittsberichte",
          "24/7 priorisierter E-Mail- und Chatsupport",
        ],
      },
    ],
  },
};
