import { motion } from "framer-motion";
import { useState } from "react";

import { useLanguage } from "~/context/LanguageContext";

import i18n from "./i18n";

export default function Questions() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (event: React.MouseEvent, index: number) => {
    if (activeIndex === index) {
      return setActiveIndex(null);
    }

    setActiveIndex(index);
    setTimeout(() => {
      (event.target as HTMLElement).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 10);
  };

  return (
    <section>
      <div className="container cs-shape_wrap_4 cs-parallax mt-5 mb-5">
        <div className="cs-shape_4 cs-to_up"></div>
        <div className="cs-shape_4 cs-to_right"></div>
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle">{t.title}</h3>
              <h2 className="cs-section_title">{t.subtitle}</h2>
              <div className="cs-height_90 cs-height_lg_45"></div>
            </div>
          </div>
          <div className="col-lg-6 offset-xl-1">
            <div className="cs-accordions cs-style1">
              {t.questions.map((q, index) => {
                const isActive = activeIndex === index;
                return (
                  <div
                    className={"cs-accordion " + (isActive ? "active" : "")}
                    key={index}
                    onClick={(event) => toggleAccordion(event, index)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    <div className="cs-accordion_head">
                      <h2 className="cs-accordion_title">{q.question}</h2>
                      <span className="cs-accordion_toggle cs-accent_color">
                        <svg
                          width="15"
                          height="8"
                          viewBox="0 0 15 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
                        </svg>
                      </span>
                    </div>
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: isActive ? "auto" : 0 }}
                      transition={{ duration: 0.25 }}
                      className="cs-accordion_body"
                      style={{
                        overflow: "hidden",
                        display: isActive ? "block" : "none",
                      }}
                    >
                      <p>{q.answer}</p>
                    </motion.div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
