/* eslint-disable jsx-a11y/anchor-is-valid */
import { motion } from "framer-motion";
import parse from "html-react-parser";
import { useState } from "react";

import { useCountry } from "~/context/CountryContext";
import { useLanguage } from "~/context/LanguageContext";

import i18n from "./i18n";
import {
  CourseIntensity,
  CourseType,
  getDiscountedPrice,
  getPricingFor,
} from "./pricingList/pricingList";
import PricingTab from "./pricingTab/PricingTab";
import PricingTable from "./pricingTable/PricingTable";
import "./Pricing.scss";

const coursesType = ["groupCourses", "individualCourses"];

export default function Pricing() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  const { country } = useCountry();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const switchTab = (event: React.MouseEvent, tabIndex: number) => {
    event.preventDefault();
    setActiveTabIndex(tabIndex);
  };

  return (
    <section>
      <div className="container" id="pricing">
        <div className="cs-tabs cs-style1">
          <div className="cs-tabs_heading">
            <div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle">{t.title}</h3>
              <h2 className="cs-section_title">{parse(t.description)}</h2>
            </div>
            <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
              {coursesType.map((courseType: string, index: number) => {
                const isActive = index === activeTabIndex;
                return (
                  <PricingTab
                    key={`course-type-${index}`}
                    label={t[courseType as CourseType].title}
                    isActive={isActive}
                    onClick={(event: React.MouseEvent) =>
                      switchTab(event, index)
                    }
                  />
                );
              })}
            </ul>
          </div>
          <div className="cs-height_85 cs-height_lg_40"></div>
          <div className="cs-tab_body">
            {coursesType.map((courseType, index) => {
              const isActive = index === activeTabIndex;
              const courseIntensity = t[courseType as CourseType].intensity;
              return (
                <motion.div
                  key={`course-tab-content-${index}`}
                  className="cs-tab"
                  id="tab_1"
                  initial={{ opacity: 0 }} // Start hidden
                  animate={{ opacity: isActive ? 1 : 0 }} // Fade in or out based on visibility
                  transition={{ duration: 1 }} // Animation duration
                  style={{ display: isActive ? "block" : "none" }}
                >
                  <div className="row">
                    {courseIntensity.map(
                      (
                        intensity: {
                          title: string;
                          description: string;
                          key: string;
                          features: string[];
                        },
                        index: number,
                      ) => {
                        return (
                          <PricingTable
                            key={`course-intensity-${index}`}
                            title={intensity.title}
                            description={intensity.description}
                            price={getPricingFor(
                              country,
                              courseType as CourseType,
                              intensity.key as CourseIntensity,
                              lang,
                            )}
                            discountedPrice={getDiscountedPrice(
                              country,
                              courseType as CourseType,
                              intensity.key as CourseIntensity,
                              lang,
                            )}
                            period={t.monthly}
                            features={intensity.features}
                            actionLabel={t.purchase}
                          />
                        );
                      },
                    )}
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
