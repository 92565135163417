export default {
  title: "التسعير والتغليف",
  description: `تقديم أفضل <br />
                أسعار لك`,
  monthly: "شهري",
  yearly: "سنوي",
  purchase: "اشترِ الآن",
  groupCourses: {
    title: "مجموعة",
    intensity: [
      {
        key: "light",
        title: "خفيف",
        description: "دورة مناسبة للمبتدئين مع جدول زمني أخف.",
        features: [
          "دروس مباشرة عبر الإنترنت مرة في الأسبوع",
          "جلسات أسئلة وأجوبة بعد كل درس",
          "الوصول إلى الجلسات المسجلة",
          "إرشاد مهني كل 3 أشهر",
          "شهادة إتمام",
          "الوصول إلى مجموعة المجتمع",
          "تقارير تقدم شهرية",
        ],
      },
      {
        key: "semiIntensive",
        title: "نصف مكثف",
        description:
          "زيادة التفاعل مع دروس مباشرة إضافية ومحتوى مركز على الوظائف.",
        features: [
          "دروس مباشرة عبر الإنترنت مرتين في الأسبوع",
          "جلسات أسئلة وأجوبة بعد كل درس",
          "الوصول إلى الجلسات المسجلة",
          "جلسات إرشاد مهني شهرية",
          "شهادة إتمام",
          "تحضير لمقابلات العمل (كل شهرين)",
          "مشاريع جماعية مع ملاحظات",
          "مواد دراسية وموارد",
          "تقارير تقدم شهرية",
        ],
      },
      {
        key: "intensive",
        title: "مكثف",
        description: "أكثر الدورات الجماعية غمرًا مع إرشاد شخصي.",
        features: [
          "دروس مباشرة عبر الإنترنت أربع مرات في الأسبوع",
          "جلسات أسئلة وأجوبة بعد كل درس",
          "الوصول إلى الجلسات المسجلة",
          "جلسات إرشاد مهني كل أسبوعين",
          "شهادة إتمام",
          "تحضير لمقابلات العمل (شهري)",
          "مشاريع جماعية مع ملاحظات من المرشد",
          "ندوات وورش عمل حصرية",
          "مواد دراسية شخصية",
          "تقارير تقدم أسبوعية",
        ],
      },
    ],
  },
  individualCourses: {
    title: "فردي",
    intensity: [
      {
        key: "light",
        title: "خفيف",
        description: "دورة مرنة مع خطط شخصية وتفاعل معتدل.",
        features: [
          "خطة تعلم شخصية",
          "دروس مباشرة عبر الإنترنت مرة في الأسبوع",
          "جلسات أسئلة وأجوبة بعد كل درس",
          "الوصول إلى الجلسات المسجلة",
          "إرشاد مهني كل 3 أشهر",
          "شهادة إتمام",
          "ملاحظات عن المشروع فرديًا",
          "مواد دراسية وموارد",
          "دعم عبر البريد الإلكتروني شهريًا",
        ],
      },
      {
        key: "semiIntensive",
        title: "نصف مكثف",
        description: "تفاعل فردي متكرر وإرشاد من خبير.",
        features: [
          "خطة تعلم شخصية",
          "دروس مباشرة عبر الإنترنت مرتين في الأسبوع",
          "جلسات أسئلة وأجوبة بعد كل درس",
          "الوصول إلى الجلسات المسجلة",
          "جلسات إرشاد مهني شهرية",
          "شهادة إتمام",
          "إرشاد مشروع مع ملاحظات من خبير",
          "تحضير لمقابلات العمل (شهري)",
          "مواد دراسية وموارد ذات أولوية",
          "تقارير تقدم كل أسبوعين",
          "دعم عبر البريد الإلكتروني والدردشة ذات أولوية",
        ],
      },
      {
        key: "intensive",
        title: "مكثف",
        description: "برنامج مكثف شامل مع إرشاد ودعم من خبير.",
        features: [
          "خطة تعلم شخصية",
          "دروس مباشرة عبر الإنترنت أربع مرات في الأسبوع",
          "جلسات أسئلة وأجوبة بعد كل درس",
          "الوصول إلى الجلسات المسجلة",
          "جلسات إرشاد مهني كل أسبوعين",
          "شهادة إتمام",
          "إرشاد مشروع مع ملاحظات معمقة من خبير",
          "تحضير لمقابلات العمل (كل أسبوعين)",
          "ندوات حصرية وموارد متقدمة",
          "تقارير تقدم أسبوعية",
          "دعم عبر البريد الإلكتروني والدردشة ذات أولوية 24/7",
        ],
      },
    ],
  },
};
